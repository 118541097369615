import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Image from 'gatsby-image'
import SquaresSVG from '../components/SquaresBackground'
import useMounted from '../hooks/use-mounted'
import styled from 'styled-components'
import 'prism-themes/themes/prism-duotone-dark.css'
import { Blog } from '../components/home/dev-diaries'
import tw from 'tailwind.macro' 
import { emptyVariants } from '../util'
import viewportMotion from 'components/viewport-motion'
import TextTransition from '../components/text-transition'
import TransitionPage from '../transition-page'

const PostContainer = styled.article`
  &.container {
    max-width: 51.875rem;
  }

  &:last-child {
    ${tw`sm:mb-12 md:mb-20`}
  }
`

const PostTemplate = ({ data: { post, morePosts }}) => {
  const { route, meta, title, remark, mainImage, published, publishedW3C } = post
  const mounted = useMounted()

  return (
    <TransitionPage className="pt-12 lg:pt-20">
      <SEO pathname={route} {...meta} />

      <PostContainer className="container">
        <header className="flex flex-col items-stretch flex-col-reverse">
          <h1 className="text-lg md:text-3xl md:mb-10">{title}</h1>
          {published && <time dateTime={publishedW3C} className="my-2">{published}</time>}

          {mainImage.sharp && (
            <div className="post-image mb-6">
              <Image loading="eager" fluid={mainImage.sharp.fluid} alt={title} />
            </div>
          )}
          {mounted && (
            <div>
              <SquaresSVG className="ml-auto block" rows={1} width={160} padding={8} />
            </div>
          )}
        </header>

        <div dangerouslySetInnerHTML={{__html: remark.html}} />
      </PostContainer>

      {morePosts && (
        <aside className="my-16">
          <h2 className="section-title">
            <viewportMotion.span
              variants={emptyVariants}
            >
              <TextTransition text="More ot read" />
            </viewportMotion.span>
          </h2>

          <Blog data={morePosts.nodes} />
        </aside>
      )}
    </TransitionPage>
  )
}

export default PostTemplate

export const PostQuery = graphql`
  query PostQuery($id: String!) {
    post(id: {eq: $id}) {
      ...CMS_Meta
      
      route
      title
      published: datePublished(formatString: "MMMM Do, YYYY")
      publishedW3C: datePublished
      mainImage {
        sharp: childImageSharp {
          fluid(maxWidth: 790, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }

          # fixedLg(width: )
        }
      }

      remark: childMarkdownRemark {
        html
      }
    }

    morePosts: allPost(
      filter: { id: { ne: $id } },
      limit: 2,
      sort: { fields: [datePublished], order: [DESC] }
    ) {
      nodes {
        ...PostArchive
      }
    }
  }
`
